// @owner ClientPlatform
/* eslint-disable jsx-a11y/label-has-associated-control */

import type {FC, PropsWithChildren} from 'react';
import React, {useState} from 'react';
import {SigninPage, type SwitchToSigninPage} from '../../helpers/pageHelpers';
import {setLocationHref} from '../../../common/location/locationHelpers';
import {pathToRedirectTo} from '../../../helpers/signupHelpers';
import {StyledParagraph, StyledAnchor, StyledRow} from '../../commonSigninStyles';
import {SigninInput, SigninInputVariant} from '../../components/signinInput/signinInput';
import {SigninButton} from '../../components/signinButton/signinButton';
import {requester} from '../../../helpers/requestHelpers';

interface InitSigninProps {
  switchToSigninPage: SwitchToSigninPage;
}

export const InitSignin: FC<PropsWithChildren<InitSigninProps>> = ({switchToSigninPage}) => {
  const [error, setError] = useState('');
  const [companySlug, setCompanySlug] = useState('');
  const [isSending, setIsSending] = useState(false);

  async function signInForCompany() {
    setIsSending(true);
    setError('');

    try {
      const response = await requester.post(`/api/1/signin/${companySlug}`);
      setLocationHref(response.data.redirect_url + pathToRedirectTo());
    } catch (e) {
      if (e.response.status === 404) {
        setError('company_not_found');
      }
    } finally {
      setIsSending(false);
    }
  }

  return (
    <form
      name="initSignInForm"
      onSubmit={(e) => {
        e.preventDefault();
        signInForCompany();
      }}
    >
      <StyledRow>
        <StyledParagraph>Enter your company&#39;s domain:</StyledParagraph>
      </StyledRow>
      <StyledRow>
        <SigninInput
          value={companySlug}
          id="company-slug"
          type="text"
          label="Company domain"
          name="slug"
          placeholder="domain"
          onChange={setCompanySlug}
          error={error === 'company_not_found' ? 'Company domain not found' : undefined}
          autoFocus
          required
          appendText=".frontapp.com"
          variant={SigninInputVariant.INLINE}
        />
      </StyledRow>

      <StyledRow>
        <SigninButton type="submit" disabled={isSending}>
          Continue
        </SigninButton>
      </StyledRow>

      <StyledRow>
        <StyledParagraph>
          <StyledAnchor
            href="#"
            onClick={(e) => {
              e.preventDefault();
              switchToSigninPage(SigninPage.LOGIN);
            }}
          >
            Sign in with your email and password
          </StyledAnchor>
        </StyledParagraph>
      </StyledRow>
    </form>
  );
};
